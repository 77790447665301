import { connect } from 'react-redux'
import CollectionAddMenu from '../components/CollectionAddMenu';

const mapStateToProps = ({ shows }) => ({
  shows
});

export default connect(
  mapStateToProps,
  null,
)(CollectionAddMenu);