import update from 'immutability-helper';
import { LOAD_COLLECTIONS, LOAD_COLLECTIONS_SUCCESS, LOAD_COLLECTIONS_ERROR, MOVE_SHOW } from '../constants/actionTypes'

const initialState = {
  isLoading: false,
  originalData: [],
  data: []
}

const collections = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_COLLECTIONS:
      return { ...state, isLoading: true };

    case LOAD_COLLECTIONS_SUCCESS:
      return { ...state, isLoading: false, data: action.collections, originalData: action.collections };

    case LOAD_COLLECTIONS_ERROR:
      return { ...state, isLoading: false };

    case MOVE_SHOW:
      return {
        ...state,
        data: state.data.map((collection) => {
          if (collection.id === action.collectionId) {
            return {
              ...collection,
              items: update(collection.items, {
                $splice: [
                  [action.dragIndex, 1],
                  [action.hoverIndex, 0, collection.items[action.dragIndex]]
                ]
              })
            };
          }
          return collection;
        })
      };

    default:
      return state
  }
}

export default collections;
