import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { link } from '../components/SupportEmail';
import cognitoUtils from '../lib/cognitoUtils'

import './Navbar.css';

class Navbar extends Component {
  state = {
    isOpen: false
  };

  constructor(props) {
    super(props);
    this.menuButtonRef = React.createRef();
  }

  onChangeTab = (e, value) => {
    if (value === 3) return;
    const { setCurrentTab } = this.props;
    setCurrentTab(value);
  }

  onSignOut = (e) => {
    e.preventDefault()
    cognitoUtils.signOutCognitoSession()
    this.closeMenu();
  }

  toggleMenu = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  closeMenu = () => {
    this.setState({ isOpen: false });
  }

  render() {
    const { currentTab, user } = this.props;
    const { isOpen } = this.state;

    return (
      <AppBar position='sticky' className='navbar'>
        <Toolbar className='navbar-primary'>
          <Typography type='title' variant='h6' className='navbar-title'>
            RNZ Freeview Manager
          </Typography>
          <Button
            className='navbar-button'
            size='small'
            ref={this.menuButtonRef}
            onClick={this.toggleMenu}
            endIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />} >
            You are logged in as "{user.userName}" ({user.email})
          </Button>
          <Popper open={isOpen} anchorEl={this.menuButtonRef.current} role={undefined} transition disablePortal placement='bottom-end'>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.closeMenu}>
                    <MenuList autoFocusItem={isOpen} id="menu-list-grow">
                      <MenuItem onClick={this.onSignOut}>Sign out</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
        <Toolbar className='navbar-secondary' variant='dense'>
          <Tabs value={currentTab} onChange={this.onChangeTab} variant="scrollable">
            <Tab disableRipple label="Dashboard" />
            <Tab disableRipple label="Featured" />
            <Tab disableRipple label="Shows" />
            <Tab disableRipple href={link} label='Contact Helpdesk'/>
          </Tabs>
        </Toolbar>
      </AppBar>
    );
  }
}

export default Navbar;
