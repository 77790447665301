import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import './ListShows.css';

export default class ListShows extends Component {
  imageUrl = ({ images = [] }) => images.length ? images[0].url : '';

  render() {
    const { onClickAdd, onClickEdit, shows = [] } = this.props;

    return (
      <Card raised className='overflow-card list-shows'>
        <CardContent className='overflow-card__content'>
          <List disablePadding component='div' >
            { shows.map(show => (
              <ListItem className='list-shows__item' key={show.id}>
                <Hidden smDown>
                  { this.imageUrl(show) ? <img src={this.imageUrl(show)} alt={this.imageUrl(show)} /> : <div className='list-shows__item__image-placeholder'/>}
                </Hidden>
                <ListItemText
                  className='list-shows__item__text'
                  primary={
                    <React.Fragment>
                      <Typography variant='subtitle2'>
                        {show.title}
                      </Typography>
                    </React.Fragment>}
                  secondaryTypographyProps={{ component: 'div' }}
                  secondary={
                    <React.Fragment>
                      <Typography variant='caption'>
                        {show.show_id}
                      </Typography>
                      <Typography variant='body2' noWrap>
                        {show.synopsis}
                      </Typography>
                    </React.Fragment>
                  } />
                  <div className='list-shows__item__edit' onClick={() => onClickEdit(show)}>
                    <Typography>Edit</Typography>
                  </div>
              </ListItem>
            ))}
          </List>
        </CardContent>
        <CardActions className='overflow-card__actions'>
          <Fab size="small" color="primary" aria-label="add" className='shows__add' style={{ marginRight: 10 }} onClick={() => onClickAdd()}>
            <AddIcon />
          </Fab>
        </CardActions>
      </Card>
    );
  }
}