import querystring from 'querystring';

class ApiError extends Error {
  constructor(message, response) {
    super(message);
    this.response = response;
  }
}

// retries the callback 10 times
const retry = (callback) => {
  // pass all the parent arguments down
  return (...args) => {
    // this function will retry the callback until there are no more errors
    const retryFunc = async (prevCalls = 0) => {
      try {
        const result = await callback(...args);
        return result;
      } catch (e) {
        if (prevCalls >= 10) {
          throw e;
        } else {
          const result = await retryFunc(prevCalls + 1);
          return result;
        }
      }
    }
    return retryFunc();
  };
}

// takes a given datetime and forces it to be in utc
const forceUtc = (dateStr) => dateStr ? new Date(dateStr).toISOString().split('.')[0]+'+00:00' : undefined;

export const uploadImage = async (file) => {
  const parts = file.name.split('.');
  const name = parts[0];
  const type = parts[1];

  const response = await fetch(process.env.REACT_APP_HOST + '/management/images/create-upload-url', {
    method: 'post',
    body: JSON.stringify({ name, type }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const { signedRequest, url } = await response.json();

   // Put the fileType in the headers for the upload
  await fetch(signedRequest, {
    method: 'put',
    body: file,
    headers: {
      'Content-Type': type
    }
  });

  return { url };
}

export const deleteImage = async (url) => {
  const response = await fetch(process.env.REACT_APP_HOST+'/management/images/delete', {
    method: 'post',
    body: JSON.stringify({ url }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const deleteResponse = response.json();
  return deleteResponse;
}

export const deleteSeason = async (seasonId) => {
  const response = await fetch(process.env.REACT_APP_HOST+`/management/delete/${seasonId}`, {
    method: 'post',
    body: JSON.stringify({ type: 'shows' }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const seasonResponse = await response.json();

  if (seasonResponse.code) {
    throw new ApiError('Season delete error', seasonResponse);
  }
  return seasonResponse;
}

export const deleteShow = async (showId) => {
  const response = await fetch(process.env.REACT_APP_HOST+`/management/delete/${showId}`, {
    method: 'post',
    body: JSON.stringify({ type: 'shows' }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const showResponse = await response.json();

  if (showResponse.code) {
    throw new ApiError('Show delete error', showResponse);
  }
  return showResponse;
}

export const updateShow = async (form) => {
  let createdImage;

  try {
    const response = await fetch(process.env.REACT_APP_HOST+`/management/patch/${form.id}`, {
      method: 'post',
      body: JSON.stringify({
        data: {
          available_from: forceUtc(form.available_from),
          available_to: forceUtc(form.available_to),
          images: form.images,
          categories: form.categories.filter(category => !!category),
          synopsis: form.synopsis,
          title: form.title,
          tags: form.tags
        }
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const showResponse = await response.json();

    if (showResponse.code) {
      throw new ApiError('Show update error', showResponse);
    }

    return showResponse;
  } catch (e) {
    if (createdImage) await deleteImage(createdImage);
    throw e;
  }

}

export const createSeason = async (data) => {
  const response = await fetch(process.env.REACT_APP_HOST+`/management/createSeason`, {
    method: 'post',
    body: JSON.stringify({
      data: {
        available_from: forceUtc(data.available_from),
        categories: data.categories,
        draft: false,
        season_number: data.season_number,
        season_id: data.season_id,
        title: data.title
      }
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const seasonResponse = await response.json();

  if (seasonResponse.code) {
    throw new ApiError('Season creation error', seasonResponse);
  }

  return seasonResponse;
}

export const addSeasonToShow = async ({ season, show }) => {
  const response = await fetch(process.env.REACT_APP_HOST+`/management/relateShowSeason`, {
    method: 'post',
    body: JSON.stringify({
      seasonid: season.id,
      showid: show.id,
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const addSeasonToShowResponse = response.json();

  if (addSeasonToShowResponse.code) {
    throw new ApiError('Season association error', addSeasonToShowResponse);
  }

  return addSeasonToShowResponse;
}

export const createShow = async (form) => {
  let createdImage;
  let showResponse;
  let show;
  let season;

  try {
    const response = await fetch(process.env.REACT_APP_HOST+`/management/createShow`, {
      method: 'post',
      body: JSON.stringify({
        data: {
          draft: false,
          show_id: form.show_id,
          available_from: forceUtc(form.available_from),
          available_to: forceUtc(form.available_to),
          images: form.images,
          categories: form.categories,
          synopsis: form.synopsis,
          title: form.title,
          tags: form.tags
        }
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    showResponse = await response.json();
    show = showResponse.data;
  } catch (e) {
    if (createdImage) await deleteImage(createdImage);
    throw e;
  }

  if (showResponse.code) {
    if (createdImage) await deleteImage(createdImage);
    throw new ApiError('Show creation error', showResponse);
  }

  try {
    const seasonResponse = await createSeason({
        available_from: show.available_from,
        categories: show.categories,
        season_number: '1',
        season_id: show.show_id ? `${show.show_id}-S01` : '',
        title: show.title
    });
    season = seasonResponse.data;
  } catch (e) {
    if (createdImage) await deleteImage(createdImage);
    await deleteShow(show.id);
    throw e;
  }

  try {
    await addSeasonToShow({ season, show });
  } catch (e) {
    if (createdImage) await deleteImage(createdImage);
    await deleteShow(show.id);
    await deleteSeason(season.id);
    throw e;
  }

  return showResponse;
}

export const getCollections = retry(async () => {
  const response = await fetch(process.env.REACT_APP_HOST + `/management/collections`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const collectionsResponse = await response.json();

  if (collectionsResponse.code) {
    throw new ApiError('Get collections error', collectionsResponse);
  }

  if (!collectionsResponse.data) {
    throw new ApiError('Get collections error', collectionsResponse);
  } else {
    return collectionsResponse;
  }
});

// this calls an endpoint that clears the "collections" custom field
export const clearVideoCollection = async (videoId) => {
  const response = await fetch(process.env.REACT_APP_HOST + `/management/collection/update/${videoId}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response.json();
};

export const updateCollection = async (collection) => {
  const response = await fetch(process.env.REACT_APP_HOST + `/management/collection/patch/${collection.id}`, {
    body: JSON.stringify({
      data: collection
    }),
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response.json();
};

export const getShows = retry(async () => {
  const response = await fetch(`${process.env.REACT_APP_HOST}/management`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const showsResponse = await response.json();
  if (!showsResponse.data) {
    throw new ApiError('Get shows error', showsResponse)
  } else {
    return showsResponse;
  }
});

export const getVideos = async ({ limit, page, sort, q }) => {
  const query = {}
  if (q !== undefined) query.query= q
  if (limit !== undefined) query.limit = limit;
  if (page !== undefined) query.page = page;
  if (sort !== undefined) query.sort = sort;

  if (process.env.REACT_APP_STUB_VIDEO_RESPONSE === 'true') {
    return {
      previous: 'unused-link.com',
      next: 'unused-link.com',
      limit: 10,
      page: 1,
      pageTotal: 1,
      videosTotal: 2,
      sort: '-updated_at',
      videos: [{
        id: 'asdf',
        name: 'My Video',
        custom_fields: {
          episode_id: 'Episode id',
          content_type: 'Series',
          show_id: 'Show id',
          ingest: 'This is something that happened'
        },
        tags: ['freeview'],
        updated_at: (new Date()).toISOString(),
      }, {
        id: 'asdf',
        name: 'My Video',
        custom_fields: {
          episode_id: 'Episode id',
          content_type: 'Series',
          show_id: 'Show id',
        },
        tags: ['freeview'],
        updated_at: (new Date()).toISOString()
      }]
    };
  } else {
    const response = await fetch(process.env.REACT_APP_HOST + `/management/videos?${querystring.stringify(query)}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  }
}