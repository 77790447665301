import { connect } from 'react-redux';
import { loadCollections } from '../actions/collections';
import Featured from '../components/Featured';

const mapStateToProps = state => ({
  collections: state.collections.data,
});

const mapDispatchToProps = (dispatch) => ({
  loadCollections: () => dispatch(loadCollections())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Featured);