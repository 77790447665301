import { combineReducers } from 'redux';
import alerts from './alerts';
import collections from './collections';
import session from './session';
import shows from './shows';
import tabs from './tabs';

export default combineReducers({
  alerts,
  collections,
  session,
  shows,
  tabs
})
