import {
  DELETE_SHOW, DELETE_SHOW_ERROR, DELETE_SHOW_SUCCESS,
  LOAD_SHOWS, LOAD_SHOWS_ERROR, LOAD_SHOWS_SUCCESS,
  SAVE_SHOW_FORM, SAVE_SHOW_FORM_ERROR, SAVE_SHOW_FORM_SUCCESS,
  SET_SHOW_FORM, UPDATE_SHOW_FORM, OPEN_SHOW_FORM,
  SAVE_SHOW_IMAGE, SAVE_SHOW_IMAGE_ERROR, SAVE_SHOW_IMAGE_SUCCESS
} from '../constants/actionTypes'
import * as api from '../lib/api';

// Initialise the Cognito sesson from a callback href
export const loadShows = () => {
  return async function (dispatch) {
    dispatch({ type: LOAD_SHOWS });
    try {
      const shows = await api.getShows();

      dispatch({
        type: LOAD_SHOWS_SUCCESS,
        data: shows.data
      });
    } catch (e) {
      console.error(e);
      dispatch({ type: LOAD_SHOWS_ERROR, error: `Loading show: ${e.message}` });
    }
  }
}

export const openShowForm = () => ({
  type: OPEN_SHOW_FORM
});

export const setShowForm = (data = {}) => ({
  type: SET_SHOW_FORM,
  data
});

export const updateShowForm = (data = {}) => ({
  type: UPDATE_SHOW_FORM,
  data
});

export const uploadShowImage = (file) => {
  return async function (dispatch, getState) {
    const form = getState().shows.form;
    const isCreating = !form.id;

    dispatch({ type: SAVE_SHOW_IMAGE });

    try {
      const response = await api.uploadImage(file);
      dispatch({ type: SAVE_SHOW_IMAGE_SUCCESS, url: response.url });
    } catch (e) {
      dispatch({ type: SAVE_SHOW_IMAGE_ERROR, message: e.message });
      return;
    }

    // if we're creating the show, we don't want to save it when an image is uploaded
    if (!isCreating) {
      return dispatch(saveShowForm());
    }
  }
}

export const saveShowForm = () => {
  return async function (dispatch, getState) {
    const form = getState().shows.form;
    // we hit the create endpoint if the show doesn't have an id/we assume it hasn't been persisted yet
    const isCreating = !form.id;

    // always ensure categories contains 'channel_id/RNZOD'
    if (!form.categories.includes('channel_id/RNZOD')) {
      form.categories.push('channel_id/RNZOD');
    }

    dispatch({ type: SAVE_SHOW_FORM });

    try {
      if (isCreating) {
        await api.createShow(form);
      } else {
        await api.updateShow(form);
      }
      await dispatch(loadShows());
      dispatch({ type: SAVE_SHOW_FORM_SUCCESS });
    } catch (e) {
      console.error(e);
      const message = (() => {
        if (e.response && e.response.data) {
          if (e.response.data.message) {
            return e.response.data.message;
          } else if (e.response.data.error && e.response.data.error.message) {
            return e.response.data.error.message;
          }
        } else {
          return e.message;
        }
      })();
      dispatch({ type: SAVE_SHOW_FORM_ERROR, message: `${isCreating ? 'Creating': 'Updating'} show: ${message}` });
    }
  }
};

export const deleteShowForm = () => {
  return async function (dispatch, getState) {
    const form = getState().shows.form;

    dispatch({ type: DELETE_SHOW });
    try {
      await api.deleteShow(form.id);
      await dispatch(loadShows());
      dispatch({ type: DELETE_SHOW_SUCCESS });
    } catch (e) {
      console.error(e);
      dispatch({ type: DELETE_SHOW_ERROR, message: `Deleting show: ${e.message}` });
    }
  }
};