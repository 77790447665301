import {
  CLOSE_SHOW_FORM, OPEN_SHOW_FORM,
  DELETE_SHOW, DELETE_SHOW_ERROR, DELETE_SHOW_SUCCESS,
  LOAD_SHOWS, LOAD_SHOWS_ERROR, LOAD_SHOWS_SUCCESS,
  SAVE_SHOW_FORM, SAVE_SHOW_FORM_ERROR, SAVE_SHOW_FORM_SUCCESS,
  SAVE_SHOW_IMAGE, SAVE_SHOW_IMAGE_ERROR, SAVE_SHOW_IMAGE_SUCCESS,
  SET_SHOW_FORM, UPDATE_SHOW_FORM, SET_TAB
} from '../constants/actionTypes'

const initialState = {
  isFormOpen: false,
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  data: [],
  form: {},
  formError: ''
}

const capiData = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_SHOW_FORM:
      return { ...state, isFormOpen: false };

    case OPEN_SHOW_FORM:
      return { ...state, isFormOpen: true };

    case DELETE_SHOW:
      return { ...state, isDeleting: true };

    case DELETE_SHOW_ERROR:
      return { ...state, formError: action.message, isDeleting: false };

    case DELETE_SHOW_SUCCESS:
      return { ...state, form: {}, isFormOpen: false, isDeleting: false };

    case LOAD_SHOWS:
      return { ...state, isLoading: true };

    case LOAD_SHOWS_SUCCESS:
      return { ...state, isLoading: false, data: action.data };

    case LOAD_SHOWS_ERROR:
      return { ...state, isLoading: false };

    case SAVE_SHOW_FORM:
      return { ...state, isSaving: true };

    case SAVE_SHOW_FORM_SUCCESS:
      return { ...state, isSaving: false, isFormOpen: false };

    case SAVE_SHOW_FORM_ERROR:
      return { ...state, formError: action.message, isSaving: false };

    case SAVE_SHOW_IMAGE:
      return { ...state, isSaving: true };

    case SAVE_SHOW_IMAGE_SUCCESS:
      return { ...state, isSaving: false, form: { ...state.form, images: [{ url: action.url }] } };

    case SAVE_SHOW_IMAGE_ERROR:
      return { ...state, isSaving: false, formError: action.message };

    case SET_SHOW_FORM:
      return { ...state, form: action.data };

    case UPDATE_SHOW_FORM:
      return { ...state, form: { ...state.form, ...action.data }};

    case SET_TAB:
      return { ...state, isFormOpen: false };

    default:
      return state
  }
}

export default capiData
