import React, { Component } from 'react';
import Collection from '../containers/Collection';
import Grid from '@material-ui/core/Grid';

export default class Featured extends Component {
  render() {
    const { collections } = this.props;

    if (!collections) return <div>"No collections found"</div>;
    return (
      <Grid container spacing={5}>
        {collections.map((collection, collKey) => (
          <Grid key={collection.id} item lg={6} xs={12}>
            <Collection test={Math.random()} collection={collection} collKey={collKey} />
          </Grid>
        ))}
      </Grid>
    );
  }
}