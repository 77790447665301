import React from 'react'
import { createBrowserHistory } from 'history'
import { Router, Route } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import Callback from './routes/Callback'
import Home from './routes/Home'

const history = createBrowserHistory();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#d24141',
    }
  },
  overrides: {
    MuiCard: {
      root: {
        maxWidth: 'calc(100vw - 1rem)'
      }
    },
    MuiCardContent: {
      root: {
        padding: '2rem 0',
      }
    },
    MuiInputLabel: {
      root: {
        color: '#d24141',
        fontSize: '12px',
        textTransform: 'uppercase',
      }
    },
    MuiTab: {
      root: {
        '&$selected': {
          color: '#d24141'
        }
      },
    },
  },
  typography: {
    caption: {
      fontStyle: 'italic'
    }
  }
});

const App = () => (
  <ThemeProvider theme={theme}>
    <DndProvider backend={'ontouchstart' in window ? TouchBackend : HTML5Backend}>
      <Router history={history}>
        <Route exact path="/" component={Home}/>
        <Route exact path="/callback" component={Callback}/>
      </Router>
    </DndProvider>
  </ThemeProvider>
)

export default App
