import React, { Component } from 'react';

import EditShow from '../containers/EditShow';
import ListShows from '../containers/ListShows';

export default class Shows extends Component {
  render() {
    const { isShowFormOpen } = this.props;

    if (isShowFormOpen) return <EditShow />
    return <ListShows />
  }
}