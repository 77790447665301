import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';

import CollectionAddMenu from '../containers/CollectionAddMenu';
import CollectionShow from './CollectionShow';

export default class Collection extends Component {
  state = {
    canDrag: false,
    hovered: null,
    lockHover: false,
    isAdding: false
  }

  openAddMenu = () => {
    this.setState({ isAdding: true });
  }

  closeAddMenu = () => {
    this.setState({ isAdding: false });
  }

  onMouseEnter = (id) => {
    const { lockHover } = this.state;
    if (lockHover) return;
    this.setState({ hovered: id });
  }

  onMouseLeave = (id) => {
    const { lockHover, hovered } = this.state;
    if (lockHover) return;
    if (hovered === id) this.setState({ hovered: null });
  }

  onAdd = ({ showId }) => {
    const { addShowToCollection, collection } = this.props;
    this.setState({ isAdding: false });
    addShowToCollection({ collection, showId });
  }

  beginDrag = () => {
    this.setState({
      lockHover: true
    });
  }

  endDrag = () => {
    const { collection, saveCollection } = this.props;
    this.setState({
      lockHover: false
    });
    saveCollection({ collectionId: collection.id });
  }

  render () {
    const { canDrag, hovered, isAdding } = this.state;
    const { collection, moveShow, removeShowFromCollection } = this.props;
    const { items = [], title } = collection;

    return (
      <Card raised className='overflow-card'>
        <CardHeader title={title} titleTypographyProps={{ variant: 'subtitle2' }} />
        <Divider />
        <CardContent className='overflow-card__content'>
          <List disablePadding component='div'>
            { items.map((show, index) => (
              <CollectionShow
                key={show.id}
                canDrag={canDrag}
                show={show}
                index={index}
                active={hovered === show.id}
                beginDrag={this.beginDrag}
                endDrag={this.endDrag}
                onMove={({ dragIndex, hoverIndex }) => moveShow({ collectionId: collection.id, dragIndex, hoverIndex })}
                onEnableDrag={() => this.setState({ canDrag: true })}
                onDisableDrag={() => this.setState({ canDrag: false })}
                onMouseEnter={() => this.onMouseEnter(show.id)}
                onMouseLeave={() => this.onMouseLeave(show.id)}
                onDelete={() => removeShowFromCollection({ collectionId: collection.id, showId: show.id })}/>
            ))}
          </List>
        </CardContent>
        <CardActions className='overflow-card__actions'>
          <Fab size='small' color='primary' aria-label='add' style={{ marginRight: 10 }} onClick={this.openAddMenu}>
            <AddIcon />
          </Fab>
        </CardActions>
        <CollectionAddMenu collection={collection} open={isAdding} onClose={this.closeAddMenu} onAdd={this.onAdd} />
      </Card>
    );
  }
}