import { connect } from 'react-redux';
import { deleteShowForm, saveShowForm, updateShowForm, uploadShowImage } from '../actions/shows';
import EditShow from '../components/EditShow';

const mapStateToProps = state => ({
  form: state.shows.form,
  isSaving: state.shows.isSaving
});

const mapDispatchToProps = (dispatch) => ({
  updateShowForm: (data) => dispatch(updateShowForm(data)),
  uploadShowImage: (file) => dispatch(uploadShowImage(file)),
  onDelete: () => dispatch(deleteShowForm()),
  onSave: () => dispatch(saveShowForm()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditShow);