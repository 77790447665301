export const CLEAR_SESSION = 'CLEAR_SESSION'
export const SET_SESSION = 'SET_SESSION'

export const SET_TAB = 'SET_TAB';

export const LOAD_COLLECTIONS = 'LOAD_COLLECTIONS';
export const LOAD_COLLECTIONS_ERROR = 'LOAD_COLLECTIONS_ERROR';
export const LOAD_COLLECTIONS_SUCCESS = 'LOAD_COLLECTIONS_SUCCESS';

export const LOAD_SHOWS = 'LOAD_SHOWS';
export const LOAD_SHOWS_SUCCESS = 'LOAD_SHOWS_SUCCESS';
export const LOAD_SHOWS_ERROR = 'LOAD_SHOWS_ERROR';

export const DELETE_SHOW = 'DELETE_SHOW';
export const DELETE_SHOW_SUCCESS = 'DELETE_SHOW_SUCCESS';
export const DELETE_SHOW_ERROR = 'DELETE_SHOW_ERROR';

export const CLOSE_SHOW_FORM = 'CLOSE_SHOW_FORM';
export const OPEN_SHOW_FORM = 'OPEN_SHOW_FORM';
export const SAVE_SHOW_FORM = 'SAVE_SHOW_FORM';
export const SAVE_SHOW_FORM_SUCCESS = 'SAVE_SHOW_FORM_SUCCESS';
export const SAVE_SHOW_FORM_ERROR = 'SAVE_SHOW_FORM_ERROR';
export const SAVE_SHOW_IMAGE = 'SAVE_SHOW_IMAGE';
export const SAVE_SHOW_IMAGE_SUCCESS = 'SAVE_SHOW_IMAGE_SUCCESS';
export const SAVE_SHOW_IMAGE_ERROR = 'SAVE_SHOW_IMAGE_ERROR';

export const SET_SHOW_FORM = 'SET_SHOW_FORM';
export const UPDATE_SHOW_FORM = 'UPDATE_SHOW_FORM';

export const MOVE_SHOW = 'MOVE_SHOW';
export const CLOSE_ALERTS = 'CLOSE_ALERTS';
export const ALERT_ERROR = 'ALERT_ERROR';