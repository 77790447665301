import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';

import MaterialTable from 'material-table';
import * as api from '../lib/api';

export default class Dashboard extends Component {
  loadVideos = async ({ pageSize, page }) => {
    const { alertError } = this.props;
    const query = "+tags:freeview"
    try {
      const result = await api.getVideos({ page: page, limit: pageSize, q: query })
      return {
        data: result.videos,
        page: result.page - 1,
        totalCount: result.videosTotal
      }
    } catch (e) {
      alertError('Error loading videos');
      return {
        data: [],
        page: 0,
        totalCount: 0
      }
    }
  }

  renderStatusBadge (row_data) {
    const { custom_fields: { ingest } = { } } = row_data;
    if (!row_data.tags.includes("freeview")){
        return <Chip icon={<CloseIcon />} label='Not synced' color='primary' />;
    } else {
      if (ingest) {
        return <Chip icon={<CloseIcon />} label='Not synced' color='primary' />;
      } else {
        return <Chip style={{ backgroundColor: 'green', width: '100%' }} icon={<CheckIcon />} label='Synced' color='primary' />;
      }
    }
    
  }

  render() {
    return (
      <Card raised>
        <MaterialTable
          options={{
            toolbar: false,
            pageSizeOptions: [10, 20, 50],
            pageSize: 10,
            emptyRowsWhenPaging: false,
            search: false,
            headerStyle: {
              fontWeight: 'bold'
            }
          }}
          columns={[
            { title: 'Name', field: 'name', editable: 'never', render: (row_data) => <Link href={`https://studio.brightcove.com/products/videocloud/media/videos/${row_data.id}`}>{row_data.name || '-'}</Link> },
            { title: 'Type', field: 'custom_fields.content_type', editable: 'never' },
            { title: 'Show', field: 'custom_fields.show_id', editable: 'never' },
            { title: 'Last updated', field: 'updated_at', type: 'datetime', editable: 'never' },
            { title: 'Status', field: 'status', editable: 'never', render: (row_data) => this.renderStatusBadge(row_data) },
            { title: 'Last message', field: 'custom_fields.ingest', editable: 'never' },
          ]}
          data={this.loadVideos} />
      </Card>
    );
  }
}