import {
  ALERT_ERROR, CLOSE_ALERTS,
  DELETE_SHOW, DELETE_SHOW_ERROR, DELETE_SHOW_SUCCESS,
  LOAD_COLLECTIONS, LOAD_COLLECTIONS_ERROR, LOAD_COLLECTIONS_SUCCESS,
  LOAD_SHOWS, LOAD_SHOWS_ERROR, LOAD_SHOWS_SUCCESS,
  SAVE_SHOW_FORM, SAVE_SHOW_FORM_ERROR, SAVE_SHOW_FORM_SUCCESS
} from '../constants/actionTypes'

const initialState = {
  isOpen: false,
  isProcessing: false,
  errors: []
}

const alerts = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_ALERTS:
      return { ...state, isOpen: false, errors: [] };

    case DELETE_SHOW_ERROR:
    case LOAD_COLLECTIONS_ERROR:
    case LOAD_SHOWS_ERROR:
    case SAVE_SHOW_FORM_ERROR:
    case ALERT_ERROR:
      return { ...state, isProcessing: false, isOpen: true, errors: state.errors.concat({ type: action.type, message: action.message }) };

    case DELETE_SHOW:
      return { ...state, isProcessing: true, errors: state.errors.filter(({ type }) => type !== DELETE_SHOW_ERROR ) };
    case LOAD_SHOWS:
      return { ...state, isProcessing: true, errors: state.errors.filter(({ type }) => type !== LOAD_SHOWS_ERROR) };
    case LOAD_COLLECTIONS:
      return { ...state, isProcessing: true, errors: state.errors.filter(({ type }) => type !== LOAD_COLLECTIONS_ERROR) };
    case SAVE_SHOW_FORM:
      return { ...state, isProcessing: true, errors: state.errors.filter(({ type }) => type !== SAVE_SHOW_FORM_ERROR) };

    case DELETE_SHOW_SUCCESS:
    case LOAD_SHOWS_SUCCESS:
    case LOAD_COLLECTIONS_SUCCESS:
    case SAVE_SHOW_FORM_SUCCESS:
      return { ...state, isProcessing: false };

    default:
      return state;
  }
}

export default alerts;
