import { CLEAR_SESSION, SET_SESSION } from '../constants/actionTypes'

const initialState = (process.env.REACT_APP_STUB_SESSION === 'true') ? {
  isLoggedIn: true,
  user: {
    userName: 'test',
    email: 'test@example.com'
  }
} : {
  isLoggedIn: false,
  user: {}
};

const session = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION:
      return Object.assign({}, action.session, { isLoggedIn: true });

    case CLEAR_SESSION:
      return initialState

    default:
      return state
  }
}

export default session
