import { ALERT_ERROR, CLOSE_ALERTS } from '../constants/actionTypes'

// Initialise the Cognito sesson from a callback href
export const closeAlerts = () => ({
  type: CLOSE_ALERTS
});

export const alertError = (message) => ({
  type: ALERT_ERROR,
  message
})