import { connect } from 'react-redux';
import ListShows from '../components/ListShows';
import { openShowForm, setShowForm } from '../actions/shows';

const mapDispatchToProps = (dispatch) => ({
  onClickAdd: () => {
    dispatch(setShowForm({
      categories: ['channel_id/RNZOD'],
      images: [],
    }));
    dispatch(openShowForm());
  },
  onClickEdit: (data) => {
    dispatch(setShowForm(data));
    dispatch(openShowForm());
  }
});

const mapStateToProps = ({ shows }) => ({
  shows: shows.data
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListShows);
