import { ALERT_ERROR, LOAD_COLLECTIONS, LOAD_COLLECTIONS_SUCCESS, LOAD_COLLECTIONS_ERROR, MOVE_SHOW } from '../constants/actionTypes'
import * as api from '../lib/api';

export const moveShow = ({ collectionId, hoverIndex, dragIndex }) => ({
  type: MOVE_SHOW,
  collectionId,
  hoverIndex,
  dragIndex
});

const COLLECTIONS_TO_LOAD = ['featured1-on-demand-belt', 'featured2-on-demand-belt'];
export const loadCollections = () => {
  return async function (dispatch) {
    dispatch({ type: LOAD_COLLECTIONS });
    try {
      const collectionsResponse = await api.getCollections();

      let collections = collectionsResponse.data || [];

      const filteredCollections = collections
        // filter out all collections except those we want to load
        .filter(collection => COLLECTIONS_TO_LOAD.includes(collection.title))
        // removes any duplicate appearances of a collection (FIXME: determine if this is necessary)
        .reduce((a,b) => ((a.filter(i => i.title === b.title).length === 0) ? a.concat([b]) : a), []);

      dispatch({ type: LOAD_COLLECTIONS_SUCCESS, collections: filteredCollections });
    } catch (e) {
      dispatch({ type: LOAD_COLLECTIONS_ERROR, message: `Load collections: ${e.message}` });
    }
  }
}

export const removeShowFromCollection = ({ collectionId, showId }) => {
  return async function (dispatch, getState) {
    const collections = getState().collections.data;
    //const shows = getState().shows.data;
    const collection = collections.find(({ id }) => collectionId === id);
    const show = collection.items.find(({ id }) => showId === id);

    dispatch({ type: LOAD_COLLECTIONS });
    try {
      await api.updateCollection({ id: collection.id, items: collection.items.filter(({ id }) => id !== showId) });
      if (show.media_id) await api.clearVideoCollection(show.media_id);
      await dispatch(loadCollections());
    } catch (e) {
      dispatch({ type: LOAD_COLLECTIONS_ERROR, message: `Remove collection: ${e.message}` });
    }
  }
}

export const addShowToCollection = ({ collection, showId }) => {
  return async function (dispatch, getState) {
    if (collection.items && collection.items.some((item) => item.id === showId)) {
      return dispatch({
        type: ALERT_ERROR,
        message: 'Show ID ' + showId + ' already exists in this collection, please remove it or change its position.',
      });
    }

    dispatch({ type: LOAD_COLLECTIONS });
    if (collection.items) {
      await api.updateCollection({ id: collection.id, items: collection.items.concat([{ 'id': showId, 'entity_type': 'shows' }]) });
    } else {
      await api.updateCollection({ id: collection.id, items: [{ 'id': showId, 'entity_type': 'shows' }] });
    }
    await dispatch(loadCollections());
  }
}

export const saveCollection = ({ collectionId }) => {
  return async function (dispatch, getState) {
    const collections = getState().collections.data;
    const originalCollections = getState().collections.originalData;
    const collection = collections.find(collection => collection.id === collectionId);
    const originalCollection = originalCollections.find(collection => collection.id === collectionId);

    if (!collection || !originalCollection) return;
    if (collection.items.map(({ id }) => id).join(' ') === originalCollection.items.map(({ id }) => id).join(' ')) return;

    dispatch({ type: LOAD_COLLECTIONS });
    await api.updateCollection({ id: collection.id, items: collection.items });
    await dispatch(loadCollections());
  }
}