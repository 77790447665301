import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import ImageUpload from './ImageUpload';

const GENRES = [
  'action', 'comedy', 'documentary', 'drama', 'entertainment', 'family', 'kids', 'kids/preschool',
  'local', 'local/te-reomaori', 'news-and-factual', 'news-and-factual/news','reality', 'lifestyle', 'sci-fi', 'sport'
];

export default class EditShow extends Component {
  state = {
    isConfirmingDelete: false
  }

  cancelDelete = () => {
    this.setState({ isConfirmingDelete: false });
  }

  channelIds = () => {
    const { form } = this.props;
    return form.categories.filter((category) => category.startsWith('channel_id/')).map(category => category.replace('channel_id/', ''));
  }

  confirmDelete = () => {
    this.setState({ isConfirmingDelete: true });
  }

  deleteShow = () => {
    const { onDelete } = this.props;
    this.setState({ isConfirmingDelete: false });
    onDelete();
  }

  // for normal fields that can directly have the value assigned
  onChangeField = (key, e) => {
    const { updateShowForm } = this.props;
    updateShowForm({
      [key]: e.target.value
    });
  }

  // for updating a time related field
  onChangeTimeField = (key, e) => {
    const { updateShowForm } = this.props;
    updateShowForm({
      [key]: new Date(e.target.value + 'Z').toISOString()
    });
  }

  onChangeCategories = (e) => {
    const { updateShowForm } = this.props;
    const value = e.target.value;
    const categories = (value || '').split(',')
    const genres = this.getGenres().map(genre => `genres/${genre}`);
    const channelIds = this.channelIds().map(channelId => `channel_id/${channelId}`);

    updateShowForm({
      categories: genres.concat(channelIds).concat(categories)
    });
  }

  onChangeGenres = (e) => {
    const { updateShowForm } = this.props;
    const value = e.target.value;
    const genres = (Array.isArray(value) ? value : [value]).map(genre => `genres/${genre}`);

    updateShowForm({ categories: genres.concat(this.getCategories()) });
  }

  onChangeTags = (e) => {
    const { updateShowForm } = this.props;
    const value = e.target.value;

    updateShowForm({ tags: value.split(',') });
  }

  getTimeField = (key) =>  {
    const { form } = this.props;
    return form[key] ? new Date(form[key]).toISOString().split('.')[0] : '';
  }

  getCategories = () => {
    const { form } = this.props;
    return form.categories.filter((category) => (!category.startsWith('genres/') && !category.startsWith('channel_id/')));
  }

  getImageUrl = () => {
    const { form } = this.props;
    const image = (form.images && form.images.length) ? form.images[0] : { url: '' };

    return image.url || '';
  }

  getGenres = () => {
    const { form } = this.props;
    return form.categories.filter((category) => category.startsWith('genres/')).map(category => category.replace('genres/', ''));
  }

  render() {
    const { isConfirmingDelete } = this.state;
    const { form, onSave, uploadShowImage, isSaving } = this.props;

    return (
      <Card raised className='edit-show'>
        <CardContent >
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item className='form-input' xs={12}>
                  <TextField
                    label="Title"
                    placeholder="New Show"
                    value={form.title || ''}
                    onChange={this.onChangeField.bind(this, 'title')}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </Grid>
                <Grid item className='form-input' xs={12}>
                  <TextField
                    label='Show ID'
                    placeholder='show-ID'
                    fullWidth
                    disabled={!!form.id}
                    value={form.show_id || ''}
                    onChange={this.onChangeField.bind(this, 'show_id')}
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </Grid>
                <Grid item className='form-input' xs={12}>
                  <TextField
                    label='Switch ID'
                    placeholder='123as-1zface-aasqwer-afasce'
                    disabled
                    fullWidth
                    value={form.id}
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </Grid>
                <Grid item className='form-input' xs={12}>
                  <TextField
                    label='Available From'
                    value={this.getTimeField('available_from')}
                    type='datetime-local'
                    fullWidth
                    onChange={this.onChangeTimeField.bind(this, 'available_from')}
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </Grid>
                <Grid item className='form-input' xs={12}>
                  <TextField
                    label='Available To'
                    value={this.getTimeField('available_to')}
                    onChange={this.onChangeTimeField.bind(this, 'available_to')}
                    type='datetime-local'
                    fullWidth
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </Grid>
                <Grid item className='form-input' xs={12}>
                  <TextField
                    label='Synopsis'
                    value={form.synopsis}
                    onChange={this.onChangeField.bind(this, 'synopsis')}
                    fullWidth
                    multiline
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item className='form-input' xs={12}>
                  <ImageUpload
                    url={this.getImageUrl()}
                    isSaving={isSaving}
                    onChange={uploadShowImage} />
                </Grid>
                <Grid item className='form-input' xs={12}>
                  <FormControl fullWidth margin='normal'>
                    <InputLabel id="demo-mutiple-name-label" shrink>Genres</InputLabel>
                    <Select
                      labelId="demo-mutiple-name-label"
                      id="demo-mutiple-name"
                      multiple
                      value={this.getGenres()}
                      onChange={this.onChangeGenres}
                      input={<Input />} >
                      {GENRES.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item className='form-input' xs={12}>
                  <TextField
                    label='Categories'
                    placeholder='foo,bar'
                    fullWidth
                    value={this.getCategories().join(',')}
                    onChange={this.onChangeCategories}
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </Grid>
                <Grid item className='form-input' xs={12}>
                  <TextField
                    label='Tags'
                    placeholder='tag1,tag2'
                    fullWidth
                    value={(form.tags || []).join(',')}
                    onChange={this.onChangeTags}
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <Dialog
          key='alert-dialog'
          open={isConfirmingDelete}
          onClose={this.cancelDelete}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>Delete Show?</DialogTitle>
          <DialogActions>
            <Button onClick={this.deleteShow} color='primary'>
              Delete
            </Button>
            <Button onClick={this.cancelDelete} style={{ color: '#aaa' }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Divider />
        { form.id ?
          <CardActions className='form-input'>
            <Button color='primary' onClick={onSave}>Save</Button>
            <Button color='primary' onClick={this.confirmDelete}>Delete</Button>
          </CardActions> :
          <CardActions className='form-input'>
            <Button color='primary' onClick={onSave}>Create</Button>
          </CardActions>
        }
      </Card>
    );
  }
}