import { connect } from 'react-redux';
import { alertError } from '../actions/alerts';
import Dashboard from '../components/Dashboard';

const mapDispatchToProps = (dispatch) => ({
  alertError: (data) => dispatch(alertError(data)),
});

export default connect(
  null,
  mapDispatchToProps,
)(Dashboard);