import { connect } from 'react-redux'
import { setTab } from '../actions/tabs';
import Navbar from '../components/Navbar';

const mapStateToProps = ({ tabs, session }) => ({
  currentTab: tabs.currentTab,
  user: session.user
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentTab: (tab) => dispatch(setTab(tab))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);