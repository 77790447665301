import React, { Component } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';

export default class CollectionAddMenu extends Component {
  render () {
    const { collection, open, onAdd, onClose, shows } = this.props;

    return (
      <Dialog
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        onClose={onClose}
        open={open} >
        <DialogTitle id="confirmation-dialog-title">Add show to {collection.title} </DialogTitle>
        <DialogContent dividers>
          <List>
            { shows.data.map(show => <MenuItem onClick={() => onAdd({ showId: show.id})} key={show.id} value={show.id}>{show.title}</MenuItem>) }
          </List>
        </DialogContent>
      </Dialog>
    );
  }
}