import { SET_TAB } from '../constants/actionTypes'

const initialState = {
  currentTab: 0,
}

const tabs = (state = initialState, action) => {
  switch (action.type) {
    case SET_TAB:
      return { ...state, currentTab: action.tab };

    default:
      return state
  }
}

export default tabs;
