import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

export default class ImageUpload extends Component {
  state = {
    tmpFile: null
  }

  hiddenFileInput = React.createRef(null);

  handleChange = e => {
    const file = e.target.files[0];
    this.setState({ tmpFile: file });
  };

  cancelSave = () => {
    this.setState({ tmpFile: null });
  }

  onSave = e => {
    const { onChange } = this.props;
    const { tmpFile } = this.state;
    if (tmpFile) {
      onChange(tmpFile);
      this.setState({ tmpFile: null });
    }
  }

  uploadImage = e => {
    this.hiddenFileInput.current.click();
  }

  renderInput = () => {
    const { file, isSaving, url } = this.props;
    const { tmpFile } = this.state;

    const myUrl = file ? URL.createObjectURL(file) : url;

    return (
      <div className='form-input__image-upload'>
        {
          myUrl ?
          <img className='form-input__image' src={myUrl} alt='show' /> :
          <div className='form-input__image' />
        }
        { isSaving && <span>Saving...</span>}
        { !isSaving && tmpFile &&
          <div>
            <Button variant='contained' color='primary' onClick={this.onSave}>Save</Button>
            <Button color='primary' onClick={this.cancelSave}>Cancel</Button>
          </div>
        }
        { !isSaving && !tmpFile &&
          <div>
            <Button variant='contained' color='primary' onClick={this.uploadImage}>Upload Image</Button>
            <input
              type="file"
              ref={this.hiddenFileInput}
              onChange={this.handleChange}
              style={{display: 'none'}} />
          </div>
        }
      </div>
    );
  }

  render () {
    return (
      <FormControl margin='normal'>
        <InputLabel shrink>Show Image</InputLabel>
        <Input disableUnderline name='show_image' inputComponent={this.renderInput}/>
      </FormControl>
    );
  }
}