import { connect } from 'react-redux'
import { addShowToCollection, removeShowFromCollection, moveShow, saveCollection } from '../actions/collections';
import Collection from '../components/Collection';

const mapDispatchToProps = (dispatch) => ({
  addShowToCollection() { return dispatch(addShowToCollection(...arguments)); },
  removeShowFromCollection() { return dispatch(removeShowFromCollection(...arguments)); },
  moveShow() { return dispatch(moveShow(...arguments)); },
  saveCollection() { return dispatch(saveCollection(...arguments)); }
});

export default connect(
  null,
  mapDispatchToProps
)(Collection);