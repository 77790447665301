import React, { useImperativeHandle, useRef, Component } from 'react'
import { DragSource, DropTarget } from 'react-dnd'

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import './CollectionShow.css';

class ShowItem extends Component {
  state = {
    isDeleting: false
  }

  componentDidUpdate(nextProps) {
    const { isDeleting } = this.state;
    if (isDeleting && !nextProps.active) {
      this.setState({ isDeleting: false });
    }
  }

  renderDefaultMenu() {
    const { onDisableDrag, onEnableDrag } = this.props;
    return [
      <IconButton key='delete' aria-label="delete" onClick={() => this.setState({ isDeleting: true })}>
        <DeleteIcon />
      </IconButton>,
      <IconButton key='move' aria-label="move" onMouseEnter={onEnableDrag} onMouseLeave={onDisableDrag} >
        <MenuIcon />
      </IconButton>
    ];
  }

  renderDeleteMenu() {
    const { onDelete } = this.props;

    return (
      <div className='collection-show__confirm-delete__inner' onClick={onDelete}>
        <CloseIcon />
        <Typography>
          Delete
        </Typography>
      </div>
    );
  }

  className () {
    const { isDeleting } = this.state;
    const { active } = this.props;

    const classNames = ['collection-show'];
    if (active) {
      classNames.push('collection-show--active');
    }
    if (isDeleting) {
      classNames.push('collection-show--delete');
    }
    return classNames.concat(' ');
  }

  secondary() {
    const { active, show } = this.props;
    const entityType = show.entity_type.charAt(0).toUpperCase() + show.entity_type.slice(1);
    return (
      <Typography noWrap style={{ marginRight: '4rem', textOverflow: active ? 'ellipsis' : 'initial' }}>
        {entityType}<span style={{ opacity: active ? 1 : 0 }}> - {show.id}</span>
      </Typography>
    )

  }

  render() {
    const { isDeleting } = this.state;
    const { active, onMouseEnter, onMouseLeave, show } = this.props;

    return (
      <ListItem ContainerProps={{ className: this.className(), onMouseEnter, onMouseLeave }}>
        <ListItemText primary={show.title} secondary={this.secondary()}/>
        <ListItemSecondaryAction hidden={!active} className={(active && isDeleting) ? 'collection-show__confirm-delete' : ''} onMouseLeave={() => this.setState({ isDeleting: false })}>
          { isDeleting ? this.renderDeleteMenu() : this.renderDefaultMenu() }
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

const Show = React.forwardRef(
  ({ active, show, onDelete, onDisableDrag, onEnableDrag, onMouseEnter, onMouseLeave, isDragging, connectDragSource, connectDropTarget }, ref) => {
    const elementRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);
    const opacity = isDragging ? 0 : 1;
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }));
    return (
      <div ref={elementRef} style={{ opacity }}>
        <ShowItem
          active={active}
          onDelete={onDelete}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onEnableDrag={onEnableDrag}
          onDisableDrag={onDisableDrag}
          show={show} />
      </div>
    )
  },
);

export default DropTarget(
  'show',
  {
    hover(props, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode()
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index
      const hoverIndex = props.index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      props.onMove({ dragIndex, hoverIndex });
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource(
    'show',
    {
      beginDrag: (props) => {
        props.beginDrag();
        return {
          id: props.id,
          index: props.index,
        };
      },
      canDrag: (props) => {
        return props.canDrag;
      },
      endDrag: (props) => {
        props.endDrag();
      }
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  )(Show),
)
