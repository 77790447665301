import { connect } from 'react-redux';
import Shows from '../components/Shows';

const mapStateToProps = ({ shows }) => ({
  isShowFormOpen: shows.isFormOpen
});

export default connect(
  mapStateToProps,
)(Shows);
