import React, { Component } from 'react';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import Dashboard from '../containers/Dashboard';
import SupportEmail from '../components/SupportEmail';
import Featured from '../containers/Featured';
import Navbar from '../containers/Navbar';
import Shows from '../containers/Shows';
import { closeAlerts } from '../actions/alerts';
import { loadCollections } from '../actions/collections';
import { loadShows } from '../actions/shows';
import cognitoUtils from '../lib/cognitoUtils';

const mapStateToProps = state => ({
  currentTab: state.tabs.currentTab,
  session: state.session,
  collections: state.collections.data,
  isProcessing: state.alerts.isProcessing,
  isAlertOpen: state.alerts.isOpen,
  alertErrors: state.alerts.errors,
  isCreatingShow: state.shows.isFormOpen && (!state.shows.form.id),
  isEditingShow: state.shows.isFormOpen && (!!state.shows.form.id),
});

const mapDispatchToProps = (dispatch) => ({
  closeAlerts: () => dispatch(closeAlerts()),
  loadCollections: () => dispatch(loadCollections()),
  loadShows: () => dispatch(loadShows())
});

class Home extends Component {
  componentDidMount () {
    const { loadCollections, loadShows, session } = this.props;

    if(!session.isLoggedIn) {
      window.location = cognitoUtils.getCognitoSignInUri();
    }

    loadCollections();
    loadShows();
  }

  componentDidUpdate () {
    // this shouldn't ever be needed, but is added for good measure
    if (!this.props.session.isLoggedIn) {
      window.location = cognitoUtils.getCognitoSignInUri();
    }
  }

  renderTab() {
    const { currentTab } = this.props;

    if (currentTab === 0) {
      return <Dashboard />;
    } else if (currentTab === 1) {
      return <Featured />;
    } else if (currentTab === 2) {
      return <Shows />;
    }
  }

  renderTitle() {
    const { currentTab, isCreatingShow, isEditingShow } = this.props;

    if (currentTab === 0) {
      return 'Episode Status';
    } else if (currentTab === 1) {
      return 'Featured Content';
    } else {
      if (isEditingShow) return 'Edit Show';
      if (isCreatingShow) return 'Create Show';
      return 'Shows';
    }
  }

  render() {
    const { isAlertOpen, alertErrors, closeAlerts, isProcessing, session } = this.props;

    return [
      session.isLoggedIn && <Navbar key='navbar' />,
      <Grid key='home' container className='home' justify='center'>
        <Grid item xs={12} style={{ margin: '3rem 1rem', maxWidth: '900px' }}>
          { session.isLoggedIn && <Typography variant='h6' className='section-title'>{this.renderTitle()}</Typography> }
          { session.isLoggedIn && this.renderTab() }
          { !session.isLoggedIn && <div key='body'>
            <h1>Freeview Content Management Tool</h1>
            <p><a href={'https://rnz-bgc-cmt.auth.ap-southeast-2.amazoncognito.com/login?response_type=code&client_id=1pb45j3eolmh0lvm592fu8g04b&redirect_uri=https://freeview.rnz.co.nz/callback'}>Redirecting you to the login page...</a></p>
          </div> }
        </Grid>
      </Grid>,
      <Dialog
        key='alert-dialog'
        open={isProcessing}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Processing...'}</DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>,
      <Dialog
        key='error-dialog'
        open={isAlertOpen}
        onClose={closeAlerts}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'An error occurred when processing this entry:'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {alertErrors.map(({ message }, i) => (
              <Typography key={i} component='span'>
                {message}
              </Typography>
            ))}
          </DialogContentText>
          <DialogContentText id='alert-dialog-contact'>
            Contact IT to resolve this error message: <SupportEmail />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAlerts} color='primary' autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    ]
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
